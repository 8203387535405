const {
  audience,
  clientid: clientID,
  domain,
  opiframeurl: opIframeUrl,
  scope,
  backChannelLogout
} = tpServerConfig.sso;

const {
  urls,
  mode,
  portal,
  support,
  imagetag
} = tpServerConfig;

const currentapp = `${window.location.origin}${basename.replace(/\/$/, '')}`;
const defaultLocale = 'en_US';
const availableLanguages = {
  'de_DE': 'Deutsch',
  'en_US': 'English',
  'fr_FR': 'Français',
  'nl_NL': 'Nederlands',
  'es_ES': 'Español',
  'pl_PL': 'Polski'
};
const isSecure = process.env.NODE_ENV !== 'development';
export default {
  sso: {
    auth0Params: {
      audience,
      clientID,
      domain,
      scope,
      redirectUri: currentapp,
      locale: defaultLocale,
    },
    auth0Opts: {
      isSecure,
      autoAuth: true,
      storageName: 'tp.portal.app.auth',
      opIframeUrl
    },
    backChannelLogout
  },
  routes: {
    auth0Domain: domain,
    gum: {
      admin: `${urls.gum.replace('/api', '')}/admin`,
      self: `${urls.gum}/self`,
      public: `${urls.gum}/public`,
      general: `${urls.gum}`
    },
    userDataService: `${urls.userDataService}/api/global_user_data`,
    language: (locale) => {
      return `${currentapp}/public/i18n/${locale || defaultLocale}/translation.json`;
    },
    news: () => {
      return `${currentapp}/wp/posts.json`;
    },
    downloads: () => {
      return `${currentapp}/wp/downloads.json`;
    },
    links: () => {
      return `${currentapp}/wp/links.json`;
    }
  },
  portal,
  support,
  urls,
  mode,
  currentapp,
  basename,
  bodyClasses: [],
  imagetag,
  defaultLocale,
  availableLanguages,
  maxDurationForUnread: 60 * 24 * 60 * 60 * 1000 //in ms - 2 months
};

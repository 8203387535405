interface WsOptions {
    reconnectionDelay: number;
    transports: string[];
    tryAllTransports: boolean;
    reconnectionAttempts: number;
    withCredentials: boolean;
    extraHeaders: {
        authorization: string;
    };
    query: {
        subjectId: string;
        authorization: string;
    }
}

enum ClientEventTypes {
    Message = 'ws-message',
    LogOut = 'ws-logout',
    Error = 'ws-error'
}

enum MessageTypes {
    UserLoggedOut = 'gum-user-logout',
    UserLoggedIn = 'gum-user-logged-in',
    UserStatusUnknown = 'client-status-unknown',
    ModuleInitialized = 'module-initialized',
    ModuleConnect = 'module-connected',
    ModuleDisconnect = 'module-disconnected',
    ModuleError = 'module-error',
}

interface Msg {
    type: MessageTypes;
    reason: string;
    subjectId?: string;
    subject?: string;
}

interface ServerToClientEvents {
    'ws-message': (a: Msg, callback: (e: number) => void) => void;
    'ws-logout': (a: Msg, callback: (e: number) => void) => void;
    'ws-error': (a: Msg, callback: (e: number) => void) => void;
}

interface ClientToServerEvents {
    ws_message: (a: Msg) => void;
    'ws-message': (a: Msg) => void;
    'ws-logout': (a: Msg) => void;
    'ws-error': (a: Msg) => void;
}

interface Opts {
    accessToken: string;
    subjectId: string;
    wssUrl: string;
    callback: (a: Msg) => void;
}

export {ClientEventTypes, MessageTypes};
export type {WsOptions, Msg, ServerToClientEvents, ClientToServerEvents, Opts};